import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Products } from 'src/app/models/products.model';
import { Papa } from 'ngx-papaparse';
import { CsvDataService } from '../../services/csv-data.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RecaptchaModule } from 'ng-recaptcha';
import { ContactFormService } from '../../services/contact-form.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
function captchaValidator(control: AbstractControl): ValidationErrors | null {
  return control.value ? null : { captchaNotResolved: true };
}
@Component({
  selector: 'app-popup-form',
  standalone: true,
  imports: [CommonModule, NzModalModule, ReactiveFormsModule, RecaptchaModule],
  templateUrl: './popup-form.component.html',
  styleUrls: ['./popup-form.component.scss']
})
export class PopupFormComponent implements OnInit {
  captcha: string;
  Products: Products[] = [];
  Services: Products[] = [];
  Subscriptions: Products[] = [];
  contactForm: FormGroup;
  isConfirmRequestPopupOpened: boolean = false;
  isLoading: boolean = false;
  isSelectChange: boolean = true;
  isUSChange: boolean = true;
  isEUChange: boolean = true;
  isIntendedFunction: boolean = true;
  isSpecificFunction: boolean = true;
  isTestingProcess: boolean = true;
  isTestResultType: boolean = true;
  USFDA: string[] = ['Class I', 'Class II', 'Class III'];
  EUFDA: string[] = ['Class A', 'Class B', 'Class C', 'Class D'];
  EUIVDR: string[] = ['IVR', 'IVS', 'IVT', 'IVP', 'IVD'];

  IVDSpecialty: string[] = [
    'Clinical Chemistry',
    'Hematology',
    'Immunology',
    'Microbiology',
    'Molecular Diagnostics',
    'Pathology',
    'Toxicology',
    'Other Test Types'
  ];

  IVDApplication: string[] = [
    'Autoimmune Disorders',
    'Genetics',
    'Infectious Diseases',
    'Mental/Behavioral Disorder',
    'Neurology',
    'Nutritional & Metabolic diseases',
    'Oncology',
    'Other Applications'
  ];

  intendedFunction: string[] = [
    'Screening',
    'Monitoring',
    'Diagnosis',
    'Aid to diagnosis',
    'Prognosis',
    'Prediction',
    'Companion diagnostic'
  ];

  specificInformation: string[] = [
    'A physiological or pathological state',
    'Congenital physical or mental impairments',
    'The predisposition to a medical condition or a disease',
    'The determination of the safety and compatibility with potential recipients',
    'The prediction of treatment response or reactions',
    'The definition or monitoring of therapeutic measures'
  ];

  testingProcess: string[] = ['Automated', 'Manual'];
  testResultType: string[] = ['Qualitative', 'Semi-quantitative', 'Quantitative'];
  constructor(
    private _formBuilder: FormBuilder,
    private _papa: Papa,
    private _csvDataService: CsvDataService,
    private _contactService: ContactFormService,
    private notification: NzNotificationService
  ) {
    this.captcha = '';
  }
  ngOnInit() {
    this.initContactForm();
    this.initCsvProductsData();
    this.initCsvServicesData();
    this.initSubscriptionsData();
  }

  initContactForm(): void {
    this.contactForm = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[A-Za-z ]+$/)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9\-()+\s]+$/)]],
      company: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      companyWeb: ['', [Validators.required]],
      companyAddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      emdn: ['', [Validators.required]],
      gmdn: ['', [Validators.required]],
      USFDA: ['', [Validators.required]],
      EUIVDR: ['', [Validators.required]],
      specimen: ['', [Validators.required]],
      productIntendedUse: ['', [Validators.required]],
      useIndication: ['', [Validators.required]],
      productComponent: ['', [Validators.required]],
      assayTesting: ['', [Validators.required]],
      productDescription: ['', [Validators.required]],
      stddoc: ['', [Validators.required]],
      deviceDesc: ['', [Validators.required]],
      subequiv: ['', [Validators.required]],
      history: ['', [Validators.required]],
      labelInfo: ['', [Validators.required]],
      warnings: ['', [Validators.required]],
      precautions: ['', [Validators.required]],
      limitations: ['', [Validators.required]],
      designInfo: ['', [Validators.required]],
      testPrinciple: ['', [Validators.required]],
      productPredicateDevice: ['', [Validators.required]],
      USFDAproductPredicateDevice: ['', [Validators.required]],
      USFDA21Code: ['', [Validators.required]],
      // EUIVR: ['', [Validators.required]],
      challengeOrQuestion: ['', [Validators.required]],
      qualityAndRegulatoryGovernance: ['', [Validators.required]],
      IVDSpecialty: ['', [Validators.required]],
      IVDApplication: ['', [Validators.required]],
      manufacturingSummary: ['', [Validators.required]],
      designVerification: ['', [Validators.required]],
      softwareVerification: ['', [Validators.required]],
      clinicalStudy: ['', [Validators.required]],
      USFDACode: ['', [Validators.required]],
      EU2017Rule: ['', [Validators.required]],
      EU2017Code: ['', [Validators.required]],
      BRSummary: ['', [Validators.required]],
      // USFDAPredicateName: ['', [Validators.required]],
      medicalDisease: ['', [Validators.required]],
      captcha: ['', [captchaValidator]],
      detectedOrMeasured: ['', [Validators.required]],
      intendedFunction: ['', [Validators.required]],
      specificInformation: ['', [Validators.required]],
      testingProcess: ['', [Validators.required]],
      testResultType: ['', [Validators.required]],
      generalSafety: ['', [Validators.required]],
      validity: ['', [Validators.required]],
      analyticPerf: ['', [Validators.required]],
      clinicalPerf: ['', [Validators.required]],
      shelfLife: ['', [Validators.required]],
      inusestability: ['', [Validators.required]],
      shippingStab: ['', [Validators.required]],
      specStab: ['', [Validators.required]],
      elecDevices: ['', [Validators.required]],
      addInfo: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.isLoading = true;

      const submitForm = this.contactForm.value;
      this._contactService.submitContactForm(submitForm).subscribe(
        (res) => {
          if (res.code === '200') {
            this.isConfirmRequestPopupOpened = true;
            this.contactForm.reset();
            grecaptcha.reset();
            this.isLoading = false;
          } else {
	    alert(res.code)
            this.notification.create('error', 'Something went wrong !', 'Please try again.');
            this.isLoading = false;
          }
        },
        (error) => {
	  alert(error.message)
          this.notification.create('error', 'Something went wrong !', 'Please try again.');
          this.isLoading = false;
        }
      );
    }
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    this.contactForm.get('captcha')?.setValue(captchaResponse);
  }

  onProductServiceChange() {
    this.isSelectChange = false;
  }

  onUsChange() {
    this.isUSChange = false;
  }

  onEuChange() {
    this.isEUChange = false;
  }

  onIntendedFunctionChange() {
    this.isIntendedFunction = false;
  }

  onSpecificFunctionChange() {
    this.isSpecificFunction = false;
  }

  onTestingProcessChange() {
    this.isTestingProcess = false;
  }

  onTestResultTypeChange() {
    this.isTestResultType = false;
  }

  initCsvProductsData(): void {
    const csvFileUrl = '../../../../assets/csv/Antrix Product List.csv';
    this._csvDataService.getCsvData(csvFileUrl).subscribe((csvData) => {
      this._papa.parse(csvData, {
        header: true,
        complete: (result) => {
          this.Products = result.data
            .filter((row: Products) => row.PartNumber && row.Name)
            .map((row: Products) => ({
              PartNumber: row.PartNumber,
              Name: row.Name,
              imgPath: row.imgPath,
              Description: row.Description
            }));
        }
      });
    });
  }

  initCsvServicesData(): void {
    const csvFileUrl = '../../../../assets/csv/Antrix Service List.csv';
    this._csvDataService.getCsvData(csvFileUrl).subscribe((csvData) => {
      this._papa.parse(csvData, {
        header: true,
        complete: (result) => {
          this.Services = result.data
            .filter((row: Products) => row.PartNumber && row.Name)
            .map((row: Products) => ({
              PartNumber: row.PartNumber,
              Name: row.Name,
              imgPath: row.imgPath,
              Description: row.Description
            }));
        }
      });
    });
  }

  initSubscriptionsData(): void {
    const csvFileUrl = '../../../../assets/csv/Antrix Product List.csv';
    this._csvDataService.getCsvData(csvFileUrl).subscribe((csvData) => {
      this._papa.parse(csvData, {
        header: true,
        complete: (result) => {
          this.Subscriptions = result.data
            .filter((row: Products) => row.PartNumber && row.Name)
            .map((row: Products) => ({
              PartNumber: row.PartNumber,
              Name: row.Name,
              imgPath: row.imgPath,
              Description: row.Description
            }));
        }
      });
    });
  }

  handleCancel(): void {
    this.isConfirmRequestPopupOpened = false;
  }
}
