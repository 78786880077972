<div class="footer-container">
  <div class="p-36px">
    <div class="text-center">
      <h2 class="footer-header text-white">ASK IRIS</h2>
    </div>
    <div class="d-flex justify-content-center">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contactUs-form">
        <div class="form-container row">
          <div class="form-input col-lg-6 col-md-12 col-sm-12">
            <label class="input-label">First name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="First name"
                formControlName="firstName"
              />
              <img
                *ngIf="
                  contactForm.get('firstName')?.invalid &&
                  (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('firstName')?.invalid &&
                (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('firstName')?.errors?.['required']">
                Please enter your first name
              </p>
              <p *ngIf="contactForm.get('firstName')?.errors?.['pattern']">
                Please enter your first name without special characters and numbers.
              </p>
            </div>
          </div>
          <div class="form-input col-lg-6 col-md-12 col-sm-12">
            <label class="input-label">Last name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                id="name"
                class="input-text"
                placeholder="Last name"
                formControlName="lastName"
              />
              <img
                *ngIf="
                  contactForm.get('lastName')?.invalid &&
                  (contactForm.get('lastName')?.dirty || contactForm.get('lastName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('lastName')?.invalid &&
                (contactForm.get('lastName')?.dirty || contactForm.get('lastName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('lastName')?.errors?.['required']">
                Please enter your last name
              </p>
              <p *ngIf="contactForm.get('lastName')?.errors?.['pattern']">
                Please enter your last name without special characters and numbers.
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Email*</label>
            <div class="input-text_wraper">
              <input type="text" class="input-text" placeholder="Email" formControlName="email" />
              <img
                *ngIf="
                  contactForm.get('email')?.invalid &&
                  (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('email')?.invalid &&
                (contactForm.get('email')?.dirty || contactForm.get('email')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('email')?.errors?.['required']">Please enter your email</p>
              <p *ngIf="contactForm.get('email')?.errors?.['email']">Please enter valid email</p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Telephone no*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Telephone no"
                formControlName="phone"
              />
              <img
                *ngIf="
                  contactForm.get('phone')?.invalid &&
                  (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('phone')?.invalid &&
                (contactForm.get('phone')?.dirty || contactForm.get('phone')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('phone')?.errors?.['required']">
                Please enter your phone number
              </p>
              <p *ngIf="contactForm.get('phone')?.errors?.['pattern']">
                Please enter a valid phone number.
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Company name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Company name"
                formControlName="company"
              />
              <img
                *ngIf="
                  contactForm.get('company')?.invalid &&
                  (contactForm.get('company')?.dirty || contactForm.get('company')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('company')?.invalid &&
                (contactForm.get('company')?.dirty || contactForm.get('company')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('company')?.errors?.['required']">
                Please enter your company name
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Job title*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Job title"
                formControlName="jobTitle"
              />
              <img
                *ngIf="
                  contactForm.get('jobTitle')?.invalid &&
                  (contactForm.get('jobTitle')?.dirty || contactForm.get('jobTitle')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('jobTitle')?.invalid &&
                (contactForm.get('jobTitle')?.dirty || contactForm.get('jobTitle')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('jobTitle')?.errors?.['required']">
                Please enter your job title
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Company website*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Company website"
                formControlName="companyWeb"
              />
              <img
                *ngIf="
                  contactForm.get('companyWeb')?.invalid &&
                  (contactForm.get('companyWeb')?.dirty || contactForm.get('companyWeb')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('companyWeb')?.invalid &&
                (contactForm.get('companyWeb')?.dirty || contactForm.get('companyWeb')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('companyWeb')?.errors?.['required']">
                Please enter your company website
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Company address*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Company address"
                formControlName="companyAddress"
              />
              <img
                *ngIf="
                  contactForm.get('companyAddress')?.invalid &&
                  (contactForm.get('companyAddress')?.dirty ||
                    contactForm.get('companyAddress')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('companyAddress')?.invalid &&
                (contactForm.get('companyAddress')?.dirty ||
                  contactForm.get('companyAddress')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('companyAddress')?.errors?.['required']">
                Please enter your company address
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">City*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="City"
                formControlName="city"
              />
              <img
                *ngIf="
                  contactForm.get('city')?.invalid &&
                  (contactForm.get('city')?.dirty ||
                    contactForm.get('city')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('city')?.invalid &&
                (contactForm.get('city')?.dirty ||
                  contactForm.get('city')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('city')?.errors?.['required']">
                Please enter your city
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">State/Province*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="State/Province"
                formControlName="state"
              />
              <img
                *ngIf="
                  contactForm.get('state')?.invalid &&
                  (contactForm.get('state')?.dirty ||
                    contactForm.get('state')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('state')?.invalid &&
                (contactForm.get('state')?.dirty ||
                  contactForm.get('state')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('state')?.errors?.['required']">
                Please enter your state
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Postal/ZIP Code*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Postal/ZIP Code"
                formControlName="zipcode"
              />
              <img
                *ngIf="
                  contactForm.get('zipcode')?.invalid &&
                  (contactForm.get('zipcode')?.dirty ||
                    contactForm.get('zipcode')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('zipcode')?.invalid &&
                (contactForm.get('zipcode')?.dirty ||
                  contactForm.get('zipcode')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('zipcode')?.errors?.['required']">
                Please enter your postal/zipcode
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Country*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Country"
                formControlName="country"
              />
              <img
                *ngIf="
                  contactForm.get('country')?.invalid &&
                  (contactForm.get('country')?.dirty ||
                    contactForm.get('country')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('country')?.invalid &&
                (contactForm.get('country')?.dirty ||
                  contactForm.get('country')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('country')?.errors?.['required']">
                Please enter your country
              </p>
            </div>
          </div>
        </div>
        <div class="form-container row">
          <div class="form-input col-lg-6 col-md-12">
            <label class="input-label">Product name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Product name"
                formControlName="productName"
              />
              <img
                *ngIf="
                  contactForm.get('productName')?.invalid &&
                  (contactForm.get('productName')?.dirty || contactForm.get('productName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productName')?.invalid &&
                (contactForm.get('productName')?.dirty || contactForm.get('productName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productName')?.errors?.['required']">
                Please enter the product name
              </p>
            </div>
          </div>
          <div class="form-input col-lg-6 col-md-12">
            <label class="input-label">Part No*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Part number"
                formControlName="productName"
              />
              <img
                *ngIf="
                  contactForm.get('productName')?.invalid &&
                  (contactForm.get('productName')?.dirty || contactForm.get('productName')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productName')?.invalid &&
                (contactForm.get('productName')?.dirty || contactForm.get('productName')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productName')?.errors?.['required']">
                Please enter the product name
              </p>
            </div>
          </div>
        </div>
        <div class="form-container row">
          <div class="form-input col-lg-6 col-md-12">
            <label class="input-label">IVD Specialty*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="IVDSpecialty"
                (change)="onUsChange()"
                [class.select-text--placeholder]="isUSChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of IVDSpecialty" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('IVDSpecialty')?.invalid &&
                  (contactForm.get('IVDSpecialty')?.dirty ||
                    contactForm.get('IVDSpecialty')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('IVDSpecialty')?.invalid &&
                (contactForm.get('IVDSpecialty')?.dirty || contactForm.get('IVDSpecialty')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('IVDSpecialty')?.errors?.['required']">
                Please enter the IVD Specialty
              </p>
            </div>
          </div>
          <div class="form-input col-lg-6 col-md-12">
            <label class="input-label">IVD Application*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="IVDApplication"
                (change)="onUsChange()"
                [class.select-text--placeholder]="isUSChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of IVDApplication" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('IVDApplication')?.invalid &&
                  (contactForm.get('IVDApplication')?.dirty ||
                    contactForm.get('IVDApplication')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('IVDApplication')?.invalid &&
                (contactForm.get('IVDApplication')?.dirty ||
                  contactForm.get('IVDApplication')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('IVDApplication')?.errors?.['required']">
                Please enter the IVD Application
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">US FDA Product Classification*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="USFDA"
                (change)="onUsChange()"
                [class.select-text--placeholder]="isUSChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of USFDA" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('USFDA')?.invalid &&
                  (contactForm.get('USFDA')?.dirty || contactForm.get('USFDA')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('USFDA')?.invalid &&
                (contactForm.get('USFDA')?.dirty || contactForm.get('USFDA')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('USFDA')?.errors?.['required']">
                Please select a US FDA product classification
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Global Medical Device Nomenclature (GMDN)</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="GMDN Code"
                formControlName="gmdn"
              />
              <img
                *ngIf="
                  contactForm.get('gmdn')?.invalid &&
                  (contactForm.get('gmdn')?.dirty || contactForm.get('gmdn')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('gmdn')?.invalid &&
                (contactForm.get('gmdn')?.dirty || contactForm.get('gmdn')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('gmdn')?.errors?.['required']">
                Please enter the GMDN code
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">US FDA 21 CFR Citation Code (8XX.XXXX)*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="US FDA 21 CFR Citation Code"
                formControlName="USFDA21Code"
              />
              <img
                *ngIf="
                  contactForm.get('USFDA21Code')?.invalid &&
                  (contactForm.get('USFDA21Code')?.dirty || contactForm.get('USFDA21Code')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('USFDA21Code')?.invalid &&
                (contactForm.get('USFDA21Code')?.dirty || contactForm.get('USFDA21Code')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('USFDA21Code')?.errors?.['required']">
                Please enter the US FDA 21 CFR Citation Code
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">US FDA Product Code*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="US FDA Product Code"
                formControlName="USFDACode"
              />
              <img
                *ngIf="
                  contactForm.get('USFDACode')?.invalid &&
                  (contactForm.get('USFDACode')?.dirty || contactForm.get('USFDACode')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('USFDACode')?.invalid &&
                (contactForm.get('USFDACode')?.dirty || contactForm.get('USFDACode')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('USFDACode')?.errors?.['required']">
                Please enter the US FDA Product Code
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">US FDA Predicate Device Number*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="US FDA Predicate Device Number"
                formControlName="USFDAproductPredicateDevice"
              />
              <img
                *ngIf="
                  contactForm.get('USFDAproductPredicateDevice')?.invalid &&
                  (contactForm.get('USFDAproductPredicateDevice')?.dirty ||
                    contactForm.get('USFDAproductPredicateDevice')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('USFDAproductPredicateDevice')?.invalid &&
                (contactForm.get('USFDAproductPredicateDevice')?.dirty ||
                  contactForm.get('USFDAproductPredicateDevice')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('USFDAproductPredicateDevice')?.errors?.['required']">
                Please enter the USFDA product predicate device
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">US FDA Predicate Name*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="US FDA Predicate Name"
                formControlName="productPredicateDevice"
              />
              <img
                *ngIf="
                  contactForm.get('productPredicateDevice')?.invalid &&
                  (contactForm.get('productPredicateDevice')?.dirty ||
                    contactForm.get('productPredicateDevice')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productPredicateDevice')?.invalid &&
                (contactForm.get('productPredicateDevice')?.dirty ||
                  contactForm.get('productPredicateDevice')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productPredicateDevice')?.errors?.['required']">
                Please enter the product predicate device name
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">EU 2017/746 IVDR Classification*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="EUIVDR"
                (change)="onEuChange()"
                [class.select-text--placeholder]="isEUChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of EUFDA" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('EUIVDR')?.invalid &&
                  (contactForm.get('EUIVDR')?.dirty || contactForm.get('EUIVDR')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('EUIVDR')?.invalid &&
                (contactForm.get('EUIVDR')?.dirty || contactForm.get('EUIVDR')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('EUIVDR')?.errors?.['required']">
                Please select an EU IVDR product classification
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">EU 2017/746 IVDR Classification Rule*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="EMDN Code"
                formControlName="EU2017Rule"
              />
              <img
                *ngIf="
                  contactForm.get('EU2017Rule')?.invalid &&
                  (contactForm.get('EU2017Rule')?.dirty || contactForm.get('EU2017Rule')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('EU2017Rule')?.invalid &&
                (contactForm.get('EU2017Rule')?.dirty || contactForm.get('EU2017Rule')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('EU2017Rule')?.errors?.['required']">
                Please enter the EU 2017/746 IVDR Classification Rule
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">EU 2017/746 IVDR Codes*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="EU2017Code"
                (change)="onEuChange()"
                [class.select-text--placeholder]="isEUChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of EUIVDR" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('EU2017Code')?.invalid &&
                  (contactForm.get('EU2017Code')?.dirty || contactForm.get('EU2017Code')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('EU2017Code')?.invalid &&
                (contactForm.get('EU2017Code')?.dirty || contactForm.get('EU2017Code')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('EU2017Code')?.errors?.['required']">
                Please select an EU 2017/746 IVDR Codes
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">European Medical Device Nomenclature (EMDN)*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="EMDN Code"
                formControlName="emdn"
              />
              <img
                *ngIf="
                  contactForm.get('emdn')?.invalid &&
                  (contactForm.get('emdn')?.dirty || contactForm.get('emdn')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('emdn')?.invalid &&
                (contactForm.get('emdn')?.dirty || contactForm.get('emdn')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('emdn')?.errors?.['required']">
                Please enter the EMDN code
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Product Intended Use / Intended Purpose*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Product Intended Use"
                formControlName="productIntendedUse"
              />
              <img
                *ngIf="
                  contactForm.get('productIntendedUse')?.invalid &&
                  (contactForm.get('productIntendedUse')?.dirty ||
                    contactForm.get('productIntendedUse')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productIntendedUse')?.invalid &&
                (contactForm.get('productIntendedUse')?.dirty ||
                  contactForm.get('productIntendedUse')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productIntendedUse')?.errors?.['required']">
                Please enter the product intended use
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Indications for Use Statement*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Indictions for Use Statement"
                formControlName="useIndication"
              />
              <img
                *ngIf="
                  contactForm.get('useIndication')?.invalid &&
                  (contactForm.get('useIndication')?.dirty ||
                    contactForm.get('useIndication')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('useIndication')?.invalid &&
                (contactForm.get('useIndication')?.dirty ||
                  contactForm.get('useIndication')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('useIndication')?.errors?.['required']">
                Please indicate your use
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">What is detected and/or measured*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="What is detected and/or measured"
                formControlName="detectedOrMeasured"
              />
              <img
                *ngIf="
                  contactForm.get('detectedOrMeasured')?.invalid &&
                  (contactForm.get('detectedOrMeasured')?.dirty ||
                    contactForm.get('detectedOrMeasured')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('detectedOrMeasured')?.invalid &&
                (contactForm.get('detectedOrMeasured')?.dirty ||
                  contactForm.get('detectedOrMeasured')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('detectedOrMeasured')?.errors?.['required']">
                What is detected and/or measured
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Intended Function*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="intendedFunction"
                (change)="onIntendedFunctionChange()"
                [class.select-text--placeholder]="isIntendedFunction"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of intendedFunction" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('intendedFunction')?.invalid &&
                  (contactForm.get('intendedFunction')?.dirty ||
                    contactForm.get('intendedFunction')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('intendedFunction')?.invalid &&
                (contactForm.get('intendedFunction')?.dirty ||
                  contactForm.get('intendedFunction')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('intendedFunction')?.errors?.['required']">
                Please select Intended Function
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Assay Testing Process and Workflow*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Assay testing process and workflow"
                formControlName="assayTesting"
              />
              <img
                *ngIf="
                  contactForm.get('assayTesting')?.invalid &&
                  (contactForm.get('assayTesting')?.dirty ||
                    contactForm.get('assayTesting')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('assayTesting')?.invalid &&
                (contactForm.get('assayTesting')?.dirty ||
                  contactForm.get('assayTesting')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('assayTesting')?.errors?.['required']">
                Please enter your assay testing workflow
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label"
              >Specific information that is intended to be provided in the context of*</label
            >
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="specificInformation"
                (change)="onSpecificFunctionChange()"
                [class.select-text--placeholder]="isSpecificFunction"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of specificInformation" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('specificInformation')?.invalid &&
                  (contactForm.get('specificInformation')?.dirty ||
                    contactForm.get('specificInformation')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('specificInformation')?.invalid &&
                (contactForm.get('specificInformation')?.dirty ||
                  contactForm.get('specificInformation')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('specificInformation')?.errors?.['required']">
                Please select Specific information
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Testing Process*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="testingProcess"
                (change)="onTestingProcessChange()"
                [class.select-text--placeholder]="isTestingProcess"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of testingProcess" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('testingProcess')?.invalid &&
                  (contactForm.get('testingProcess')?.dirty ||
                    contactForm.get('testingProcess')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('testingProcess')?.invalid &&
                (contactForm.get('testingProcess')?.dirty ||
                  contactForm.get('testingProcess')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('testingProcess')?.errors?.['required']">
                Please select Testing Process
              </p>
            </div>
          </div>
          <div class="form-input">
            <label class="input-label">Test Result Type*</label>
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="testResultType"
                (change)="onTestResultTypeChange()"
                [class.select-text--placeholder]="isTestResultType"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select an item
                </option>
                <option *ngFor="let data of testResultType" [value]="data">{{ data }}</option>
              </select>
              <img
                *ngIf="
                  contactForm.get('testResultType')?.invalid &&
                  (contactForm.get('testResultType')?.dirty ||
                    contactForm.get('testResultType')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('testResultType')?.invalid &&
                (contactForm.get('testResultType')?.dirty ||
                  contactForm.get('testResultType')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('testResultType')?.errors?.['required']">
                Please select Test Result Type
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Medical Disease / Condition Detected*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Medical Disease / Condition Detected"
                formControlName="medicalDisease"
              />
              <img
                *ngIf="
                  contactForm.get('medicalDisease')?.invalid &&
                  (contactForm.get('medicalDisease')?.dirty ||
                    contactForm.get('medicalDisease')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('medicalDisease')?.invalid &&
                (contactForm.get('medicalDisease')?.dirty ||
                  contactForm.get('medicalDisease')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('medicalDisease')?.errors?.['required']">
                Please enter the Medical Disease / Condition Detected
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Specimen Type*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Specimen Type"
                formControlName="specimen"
              />
              <img
                *ngIf="
                  contactForm.get('specimen')?.invalid &&
                  (contactForm.get('specimen')?.dirty || contactForm.get('specimen')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('specimen')?.invalid &&
                (contactForm.get('specimen')?.dirty || contactForm.get('specimen')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('specimen')?.errors?.['required']">
                Please enter the specimen type
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label"
              >Product / Device Components for e.g. Reagents, Instrument, Software, Accessories.
              *</label
            >
            <p class="input-label fs-12px">
              (Please provide list of Part No, Component Name, Storage Temperature)
            </p>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Please provide list of Part No, Component Name, Storage Temperature"
                formControlName="productComponent"
              />
              <img
                *ngIf="
                  contactForm.get('productComponent')?.invalid &&
                  (contactForm.get('productComponent')?.dirty ||
                    contactForm.get('productComponent')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productComponent')?.invalid &&
                (contactForm.get('productComponent')?.dirty ||
                  contactForm.get('productComponent')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productComponent')?.errors?.['required']">
                Please enter the product component
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Product Overview* </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Product Description"
                formControlName="productDescription"
              />
              <img
                *ngIf="
                  contactForm.get('productDescription')?.invalid &&
                  (contactForm.get('productDescription')?.dirty ||
                    contactForm.get('productDescription')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('productDescription')?.invalid &&
                (contactForm.get('productDescription')?.dirty ||
                  contactForm.get('productDescription')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('productDescription')?.errors?.['required']">
                Please enter product description
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Standard/Guidance Document Reference*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Standard/Guidance document"
                formControlName="stddoc"
              />
              <img
                *ngIf="
                  contactForm.get('stddoc')?.invalid &&
                  (contactForm.get('stddoc')?.dirty ||
                    contactForm.get('stddoc')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('stddoc')?.invalid &&
                (contactForm.get('stddoc')?.dirty ||
                  contactForm.get('stddoc')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('stddoc')?.errors?.['required']">
                Please enter your standard and guidance document
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Device Description and Specification*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Device description and specification"
                formControlName="deviceDesc"
              />
              <img
                *ngIf="
                  contactForm.get('deviceDesc')?.invalid &&
                  (contactForm.get('deviceDesc')?.dirty ||
                    contactForm.get('deviceDesc')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('deviceDesc')?.invalid &&
                (contactForm.get('deviceDesc')?.dirty ||
                  contactForm.get('deviceDesc')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('deviceDesc')?.errors?.['required']">
                Please enter device description and specification
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Substantial Equivalence*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Substantial equivalence"
                formControlName="subequiv"
              />
              <img
                *ngIf="
                  contactForm.get('subequiv')?.invalid &&
                  (contactForm.get('subequiv')?.dirty ||
                    contactForm.get('subequiv')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('subequiv')?.invalid &&
                (contactForm.get('subequiv')?.dirty ||
                  contactForm.get('subequiv')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('subequiv')?.errors?.['required']">
                Please enter substantial equivalence
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Reference to Previous Device Generation(s) and/or Similar Devices or Device History*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Device history"
                formControlName="history"
              />
              <img
                *ngIf="
                  contactForm.get('history')?.invalid &&
                  (contactForm.get('history')?.dirty ||
                    contactForm.get('history')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('history')?.invalid &&
                (contactForm.get('history')?.dirty ||
                  contactForm.get('history')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('history')?.errors?.['required']">
                Please enter your device history
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Labeling Information to be Supplied by the Manufacturer*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Labeling information"
                formControlName="labelInfo"
              />
              <img
                *ngIf="
                  contactForm.get('labelInfo')?.invalid &&
                  (contactForm.get('labelInfo')?.dirty ||
                    contactForm.get('labelInfo')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('labelInfo')?.invalid &&
                (contactForm.get('labelInfo')?.dirty ||
                  contactForm.get('labelInfo')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('labelInfo')?.errors?.['required']">
                Please enter your labeling information
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Warnings*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Warnings"
                formControlName="warnings"
              />
              <img
                *ngIf="
                  contactForm.get('warnings')?.invalid &&
                  (contactForm.get('warnings')?.dirty ||
                    contactForm.get('warnings')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('warnings')?.invalid &&
                (contactForm.get('warnings')?.dirty ||
                  contactForm.get('warnings')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('warnings')?.errors?.['required']">
                Please enter your warnings
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Precautions*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Precautions"
                formControlName="precautions"
              />
              <img
                *ngIf="
                  contactForm.get('precautions')?.invalid &&
                  (contactForm.get('precautions')?.dirty ||
                    contactForm.get('precautions')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('precautions')?.invalid &&
                (contactForm.get('precautions')?.dirty ||
                  contactForm.get('precautions')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('precautions')?.errors?.['required']">
                Please enter your precautions
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Limitations*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Limitations"
                formControlName="limitations"
              />
              <img
                *ngIf="
                  contactForm.get('limitations')?.invalid &&
                  (contactForm.get('limitations')?.dirty ||
                    contactForm.get('limitations')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('limitations')?.invalid &&
                (contactForm.get('limitations')?.dirty ||
                  contactForm.get('limitations')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('limitations')?.errors?.['required']">
                Please enter your limitations
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Design Information*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Design information"
                formControlName="designInfo"
              />
              <img
                *ngIf="
                  contactForm.get('designInfo')?.invalid &&
                  (contactForm.get('designInfo')?.dirty ||
                    contactForm.get('designInfo')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('designInfo')?.invalid &&
                (contactForm.get('designInfo')?.dirty ||
                  contactForm.get('designInfo')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('designInfo')?.errors?.['required']">
                Please enter your design information
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Product Test Principle* </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Product Test Principle"
                formControlName="testPrinciple"
              />
              <img
                *ngIf="
                  contactForm.get('testPrinciple')?.invalid &&
                  (contactForm.get('testPrinciple')?.dirty ||
                    contactForm.get('testPrinciple')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('testPrinciple')?.invalid &&
                (contactForm.get('testPrinciple')?.dirty ||
                  contactForm.get('testPrinciple')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('testPrinciple')?.errors?.['required']">
                Please enter Product Test Principle
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Benefit - Risk Analysis and Risk Management*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Benefit - Risk Summary"
                formControlName="BRSummary"
              />
              <img
                *ngIf="
                  contactForm.get('BRSummary')?.invalid &&
                  (contactForm.get('BRSummary')?.dirty || contactForm.get('BRSummary')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('BRSummary')?.invalid &&
                (contactForm.get('BRSummary')?.dirty || contactForm.get('BRSummary')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('BRSummary')?.errors?.['required']">
                Please enter the Benefit - Risk Summary
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label"
              >Clinical Study Patient Demographic Parameters Summary:*</label
            >
            <p class="input-label fs-12px">
              (Please list Age, Gender, Race/Ethnicity, Geographic Location, Socioeconomic Status,
              Health Status, Medical History, Genetic Factors, Weight, Height, BMI Smoking Status,
              Pregnancy Status, Lifestyle factors, Medication Use)
            </p>
            <div class="input-text_wraper">
              <textarea
                type="text"
                class="input-text text-white"
                placeholder="Please list Age, Gender, Race/Ethnicity, Geographic Location, Socioeconomic Status, Health Status, Medical History, Genetic Factors, Weight, Height, BMI Smoking Status, Pregnancy Status, Lifestyle factors, Medication Use"
                formControlName="clinicalStudy"
                rows="3"
              ></textarea>
              <img
                *ngIf="
                  contactForm.get('clinicalStudy')?.invalid &&
                  (contactForm.get('clinicalStudy')?.dirty ||
                    contactForm.get('clinicalStudy')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('clinicalStudy')?.invalid &&
                (contactForm.get('clinicalStudy')?.dirty ||
                  contactForm.get('clinicalStudy')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('clinicalStudy')?.errors?.['required']">
                Please enter the Clinical Study Patient Demographic Parameters Summary
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Software Verification and Validation Summary * </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Please list if it has AI software components"
                formControlName="softwareVerification"
              />
              <img
                *ngIf="
                  contactForm.get('softwareVerification')?.invalid &&
                  (contactForm.get('softwareVerification')?.dirty ||
                    contactForm.get('softwareVerification')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('softwareVerification')?.invalid &&
                (contactForm.get('softwareVerification')?.dirty ||
                  contactForm.get('softwareVerification')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('softwareVerification')?.errors?.['required']">
                Please enter Software Verification and Validation Summary
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Product Verification and Validation Summary* </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Product Verification and Validation Summary"
                formControlName="designVerification"
              />
              <img
                *ngIf="
                  contactForm.get('designVerification')?.invalid &&
                  (contactForm.get('designVerification')?.dirty ||
                    contactForm.get('designVerification')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('designVerification')?.invalid &&
                (contactForm.get('designVerification')?.dirty ||
                  contactForm.get('designVerification')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('designVerification')?.errors?.['required']">
                Please enter Design Verification and Validation Summary
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Manufacturing Information* </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Manufacturing information"
                formControlName="manufacturingSummary"
              />
              <img
                *ngIf="
                  contactForm.get('manufacturingSummary')?.invalid &&
                  (contactForm.get('manufacturingSummary')?.dirty ||
                    contactForm.get('manufacturingSummary')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('manufacturingSummary')?.invalid &&
                (contactForm.get('manufacturingSummary')?.dirty ||
                  contactForm.get('manufacturingSummary')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('manufacturingSummary')?.errors?.['required']">
                Please enter Manufacturing Summary
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">General Safety and Performance Requirements*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="General safety and performance"
                formControlName="generalSafety"
              />
              <img
                *ngIf="
                  contactForm.get('generalSafety')?.invalid &&
                  (contactForm.get('generalSafety')?.dirty ||
                    contactForm.get('generalSafety')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('generalSafety')?.invalid &&
                (contactForm.get('generalSafety')?.dirty ||
                  contactForm.get('generalSafety')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('generalSafety')?.errors?.['required']">
                Please enter your general safety and performance requirements
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Scientific Validity*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Scientific validity"
                formControlName="validity"
              />
              <img
                *ngIf="
                  contactForm.get('validity')?.invalid &&
                  (contactForm.get('validity')?.dirty ||
                    contactForm.get('validity')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('validity')?.invalid &&
                (contactForm.get('validity')?.dirty ||
                  contactForm.get('validity')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('validity')?.errors?.['required']">
                Please enter your scientific validity
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Analytic Performance*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Analytic performance"
                formControlName="analyticPerf"
              />
              <img
                *ngIf="
                  contactForm.get('analyticPerf')?.invalid &&
                  (contactForm.get('analyticPerf')?.dirty ||
                    contactForm.get('analyticPerf')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('analyticPerf')?.invalid &&
                (contactForm.get('analyticPerf')?.dirty ||
                  contactForm.get('analyticPerf')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('analyticPerf')?.errors?.['required']">
                Please enter your analytical performance
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Clinical Performance*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Clinical performance"
                formControlName="clinicalPerf"
              />
              <img
                *ngIf="
                  contactForm.get('clinicalPerf')?.invalid &&
                  (contactForm.get('clinicalPerf')?.dirty ||
                    contactForm.get('clinicalPerf')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('clinicalPerf')?.invalid &&
                (contactForm.get('clinicalPerf')?.dirty ||
                  contactForm.get('clinicalPerf')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('clinicalPerf')?.errors?.['required']">
                Please enter your clinical performance
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Claimed Shelf-life*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Claimed shelf-life"
                formControlName="shelfLife"
              />
              <img
                *ngIf="
                  contactForm.get('shelfLife')?.invalid &&
                  (contactForm.get('shelfLife')?.dirty ||
                    contactForm.get('shelfLife')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('shelfLife')?.invalid &&
                (contactForm.get('shelfLife')?.dirty ||
                  contactForm.get('shelfLife')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('shelfLife')?.errors?.['required']">
                Please enter your claimed shelf-life
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">In-use Stability*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="In-use stability"
                formControlName="inusestability"
              />
              <img
                *ngIf="
                  contactForm.get('inusestability')?.invalid &&
                  (contactForm.get('inusestability')?.dirty ||
                    contactForm.get('inusestability')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('inusestability')?.invalid &&
                (contactForm.get('inusestability')?.dirty ||
                  contactForm.get('inusestability')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('inusestability')?.errors?.['required']">
                Please enter your in-use stability
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Shipping Stability*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Shipping Stability"
                formControlName="shippingStab"
              />
              <img
                *ngIf="
                  contactForm.get('shippingStab')?.invalid &&
                  (contactForm.get('shippingStab')?.dirty ||
                    contactForm.get('shippingStab')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('shippingStab')?.invalid &&
                (contactForm.get('shippingStab')?.dirty ||
                  contactForm.get('shippingStab')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('shippingStab')?.errors?.['required']">
                Please enter your shipping stability
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Specimen Stability*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Specimen stability"
                formControlName="specStab"
              />
              <img
                *ngIf="
                  contactForm.get('specStab')?.invalid &&
                  (contactForm.get('specStab')?.dirty ||
                    contactForm.get('specStab')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('specStab')?.invalid &&
                (contactForm.get('specStab')?.dirty ||
                  contactForm.get('specStab')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('specStab')?.errors?.['required']">
                Please enter your specimen stability
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Electrical and/or Electronically Engineered Devices*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Electrical devices"
                formControlName="elecDevices"
              />
              <img
                *ngIf="
                  contactForm.get('elecDevices')?.invalid &&
                  (contactForm.get('elecDevices')?.dirty ||
                    contactForm.get('elecDevices')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('elecDevices')?.invalid &&
                (contactForm.get('elecDevices')?.dirty ||
                  contactForm.get('elecDevices')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('elecDevices')?.errors?.['required']">
                Please enter your electronic devices
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label">Additional Information Required in Specific Cases*</label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Additional info"
                formControlName="addInfo"
              />
              <img
                *ngIf="
                  contactForm.get('addInfo')?.invalid &&
                  (contactForm.get('addInfo')?.dirty ||
                    contactForm.get('addInfo')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('addInfo')?.invalid &&
                (contactForm.get('addInfo')?.dirty ||
                  contactForm.get('addInfo')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('addInfo')?.errors?.['required']">
                Please enter additional info
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label"
              >Select quality and regulatory governance documents to be generated by the LDTtoIVD
              IRIS AI Agent*</label
            >
            <div class="input-text_wraper">
              <select
                type="text"
                class="select-text"
                name="Select Product and Services"
                formControlName="qualityAndRegulatoryGovernance"
                (change)="onProductServiceChange()"
                [class.select-text--placeholder]="isSelectChange"
              >
                <option class="select-text_placeholder text-white" value="" disabled selected>
                  Select Products or Services
                </option>
                <optgroup class="select-outgroup" label="Services">
                  <option *ngFor="let service of Services" [value]="service.Name">
                    {{ service.Name }}
                  </option>
                </optgroup>
                <optgroup class="select-outgroup" label="Products">
                  <option *ngFor="let product of Products" [value]="product.Name">
                    {{ product.Name }}
                  </option>
                </optgroup>
                <optgroup class="select-outgroup" label="Subscriptions">
                  <option *ngFor="let sub of Subscriptions" [value]="sub.Name">
                    {{ sub.Name }}
                  </option>
                </optgroup>
              </select>
              <img
                *ngIf="
                  contactForm.get('qualityAndRegulatoryGovernance')?.invalid &&
                  (contactForm.get('qualityAndRegulatoryGovernance')?.dirty ||
                    contactForm.get('qualityAndRegulatoryGovernance')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('qualityAndRegulatoryGovernance')?.invalid &&
                (contactForm.get('qualityAndRegulatoryGovernance')?.dirty ||
                  contactForm.get('qualityAndRegulatoryGovernance')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('qualityAndRegulatoryGovernance')?.errors?.['required']">
                Please select Products or Services
              </p>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-input">
            <label class="input-label"
              >Please provide information about your specific challenge or question*
            </label>
            <div class="input-text_wraper">
              <input
                type="text"
                class="input-text"
                placeholder="Please give us your message"
                formControlName="challengeOrQuestion"
              />
              <img
                *ngIf="
                  contactForm.get('challengeOrQuestion')?.invalid &&
                  (contactForm.get('challengeOrQuestion')?.dirty ||
                    contactForm.get('challengeOrQuestion')?.touched)
                "
                src="assets/icons/icon-alert-circle.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="
                contactForm.get('challengeOrQuestion')?.invalid &&
                (contactForm.get('challengeOrQuestion')?.dirty ||
                  contactForm.get('challengeOrQuestion')?.touched)
              "
              class="error-text"
            >
              <p *ngIf="contactForm.get('challengeOrQuestion')?.errors?.['required']">
                Please enter your question
              </p>
            </div>
          </div>
        </div>
        <div class="form-container flex-column align-items-center">
          <p class="text-white fs-10px text-center">
            We are committed to protecting and respecting your privacy, and we’ll use your personal
            information to administer your account and to provide the products and services you
            requested from us.
          </p>
          <div class="form-input flex-row-reverse justify-content-center">
            <label style="white-space: normal" class="input-label fs-12px"
              >I consent to the privacy policy and understand that my personal data will be stored
              and processed for consulting services
            </label>
            <div class="">
              <input type="checkbox" />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <re-captcha
            class="m-auto"
            (resolved)="resolved($event)"
            siteKey="6LdQn3gqAAAAAAbhsPNx0etHCwG_QrIpDHqRJJVW"
          ></re-captcha>
        </div>
        <button [disabled]="contactForm.invalid || isLoading" class="form-submit_btn">
          Submit
          <div *ngIf="isLoading" class="request-loading">
            <div class="mb-1">
              <img src="assets/icons/icon-loading.svg" alt="" class="request-loading_icon" />
            </div>
          </div>
        </button>
      </form>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isConfirmRequestPopupOpened"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
  nzMaskClosable="true"
  nzClassName="request-livedemo-modal"
>
  <ng-container *nzModalContent>
    <div>
      <div class="header d-flex align-items-center flex-gap-2">
        <img
          style="max-width: 133px; max-height: 56px"
          class="header-img bg-img"
          src="assets/imgs/logo-1.png"
        />
        <img src="assets/imgs/logo-2.png" alt="" />
      </div>
      <div class="content-wrapper w-100">
        <div
          class="d-flex justify-content-between align-items-center flex-column flex-gap-16px w-100"
        >
          <img class="close-icon" src="assets/icons/icon-circle-check-2.svg" />
          <p class="popup-title">
            Thank you for submitting the form. Our team will review your message and get back to you
            shortly.
          </p>
          <p class="popup-title">
            Thank you for considering LDTtoIVD. We look forward to assisting you.
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
